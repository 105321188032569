@import '@styles/_variables.scss';
.callList {
  font-size: $font-size-lg;
  line-height: 26px;
  max-width: 200px;
  padding: 0px;
  li {
    padding: 0 0 5px 0px;
    border-bottom: 1px solid #909090;
  }
}
.elFooterMain {
  position: relative;
  color: #02090c;
  background-color: #02090c;
  min-height: 64px;
  padding: 24px 0 0 0px;
  // &:before {
  //   content: '';
  //   background: rgba(0, 0, 0, 0.8);
  //   position: absolute;
  //   inset: 0;
  // }
  .EL-footerLogowhite {
    max-width: 119px;
  }
  .MuiGrid-container {
    align-items: flex-start;
  }
  .el-footerInfo {
    color: #7f8389;
    max-width: 320px;
    padding: 16px 0px;
    line-height: 20px;
    font-size: 14px;
  }
  .el-socialLinks {
    .MuiIconButton-root {
      color: #d3d4dccc;
      margin-right: 4px;
      border-radius: 0px;
      &:first-child {
        margin-left: -12px;
      }
      svg {
        font-size: 40px;
      }
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .el-footerInfo {
  }
  .el-footerLinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-footerLinkinner {
      display: flex;
      align-items: flex-start;
      h6 {
        background: none;
        color: #fff;
        font-size: 15px;
        position: relative;
        margin-bottom: 6px;
      }
      ul {
        padding: 0 16px;
        li {
          color: #d3d4dc99;
          font-size: 15px;
          a {
            padding: 2px 16px;
            &:hover {
              color: #d3d4dc;
            }
          }
        }
      }
      .MuiAccordion-root {
        background: none;
        margin: 0px;
        border-bottom: 1px solid #353a3d;
        padding: 16px 0px;
        border-radius: 0px;
        .MuiAccordionDetails-root {
          padding: 0px;
          nav {
            padding: 16px 0 0 0px;
            border: none;
          }
        }
        .MuiAccordionSummary-content {
          margin: 0px;
          color: #fff;
        }
        .MuiAccordionSummary-root {
          padding: 0px 0 0px 0px;
          min-height: inherit;
          .MuiSvgIcon-root {
            color: #fff;
          }
        }
      }
    }
    .MuiButtonGroup-grouped {
      min-width: 40px;
      box-shadow: none;
      padding: 0px $spacing-sm;
      color: $gray-pastel;
      line-height: 19px;
      border-color: $gray-pastel !important;
      text-decoration: underline;
      text-transform: capitalize;
      &:hover {
        color: $primary-white;
      }
    }
    @media (max-width: 767px) {
      .el-footerLinkinner {
        nav {
          border-bottom: 1px solid #353a3d;
          padding: 12px 0 0px 0px;
          ul {
            padding: 0 0px 16px 0px;
            li {
              padding: 2px 0px;
            }
          }
        }
        .MuiAccordion-root {
          &:first-child {
            border-top: 1px solid #353a3d;
          }
          .MuiAccordionDetails-root {
            nav {
              padding: 12px 0 0px 0px;
              // border-bottom: 1px solid #353a3d;

              h6 {
                margin: 0px;
              }
              ul {
                margin: 0px;
                padding: 0px;
                li {
                  padding: 2px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .EL-footerBottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #d3d4dc99;
    margin-top: 40px;
    svg {
      opacity: 0.2;
    }
    span {
      padding-bottom: 24px;
      padding-right: 24px;
    }
    @media (max-width: 767px) {
      margin: 0px;
      margin-top: 16px;
      span {
        padding: 20px 16px;
        text-align: center;
        font-size: 14px;
      }
      svg {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    padding: $spacing-sm 4px;
    .el-footerInfo {
      text-align: left;
      font-size: $font-size-default;
      line-height: $spacing-md;
      padding-top: 4px;
    }
    // .MuiGrid-container {
    //   p {
    //     text-align: left;
    //     font-size: $font-size-default;
    //     line-height: $spacing-md;
    //   }
    // }
    .el-socialLinks {
      padding: 4px 0 16px 0px;
      display: block;
    }
    .el-footerLinks {
      padding: 0px;
      display: block;
      a {
        font-size: $font-size-sm;
        &:hover {
          color: #fff;
        }
      }

      .el-footerLinkinner {
        display: block;
        text-align: left;
        line-height: $spacing-xl;
        h6 {
          padding: 0px;
          margin: 0px;
        }
        ul {
          li {
            a {
              padding: 0px;
              .MuiTypography-root {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.4, 1.4);
    background-color: rgba(0, 0, 0, 0);
  }
}
