@import '@styles/_variables.scss';
.mainPage {
  & .el-detailHeader {
    .sticky-header {
      // display: none;
    }
    .headerMain {
      box-shadow: 0 2px 2.5px 0 rgba(0, 0, 0, 0.1);
      background-color: $primary-white;
      .el-login {
        .MuiButtonBase-root {
          background: $color-primary;
          color: $primary-white;
          padding: 3px 14px;
          span {
            font-weight: $font-weight-bold;
            font-size: $font-size-default;
          }
        }
      }
      &.mui-fixed {
        position: fixed;
      }
      .el-menu {
        color: $primary-black;
      }
    }
    .MuiButtonBase-root {
      background: none;
      color: $gray-dark;
    }
    .eliList {
      .MuiListItem-root {
        &.el-contactNum {
          font-size: $font-size-md;
          .MuiButtonBase-root {
            background: $gray-dark;
            color: $primary-white;
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .EL-AvtarDrawer,
    .EL-AvtarLogin {
      .EL-logedinWebButton {
        &.MuiButtonBase-root {
          background: #000;
          color: #fff;
          .MuiButton-startIcon {
            .MuiAvatar-root {
              background-color: #fff;
              .MuiSvgIcon-root {
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .EL-AvtarDrawer,
  .EL-AvtarLogin {
    .EL-logedinWebButton {
      background: #fff;
      color: #000;
      border-radius: 20px;
      padding: 2px 10px 2px 4px;
      font-size: 12px;
      width: auto;
      min-width: inherit;
      &:hover {
        background: #fff;
        color: #000;
      }
      &:before {
        display: none;
      }
      .MuiButton-endIcon {
        margin-left: 2px;
      }
      .MuiButton-startIcon {
        margin-right: 8px;
        .MuiAvatar-root {
          width: 22px;
          height: 22px;
          background-color: #e1e1e1;
          font-size: 12px;
          color: #000;
          .MuiSvgIcon-root {
            color: #000;
            max-width: 24px;
          }
        }
      }
    }
  }
  .sticky-header {
    .EL-AvtarDrawer,
    .EL-AvtarLogin {
      .EL-logedinWebButton {
        &.MuiButtonBase-root {
          background: #000;
          color: #fff;
          .MuiButton-startIcon {
            .MuiAvatar-root {
              background-color: #fff;
              .MuiSvgIcon-root {
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .mui-fixed {
    position: fixed;
  }
  .headerMain {
    box-shadow: none;
    .logo {
      display: flex;
      & a {
        display: flex;
        @media (max-width: 768px) {
          margin-left: -16px;
        }
      }
      img,
      svg {
        max-height: 32px;
        @media (max-width: 768px) {
          max-width: 150px;
          max-height: 26px;
        }
      }
    }
    .el-menu {
      color: #fffffff2;
      font-size: 1.3em;
    }
    &.sticky-header {
      background: $primary-white;
      animation: goDown 0.5s ease-in-out forwards;
      box-shadow: 0 2px 2.5px 0 rgba(0, 0, 0, 0.1);
      .logo {
        display: flex;
        img {
          //max-height: $spacing-xxl;
        }
      }
      .el-menu {
        color: $primary-black;
      }
      .MuiButtonBase-root {
        background: none;
        color: $gray-dark;
      }
      .el-login {
        .MuiButtonBase-root {
          background: $color-primary;
          color: $primary-white;
          padding: 3px 14px;
          span {
            font-weight: $font-weight-bold;
            font-size: $font-size-default;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerMain {
      box-shadow: 0 2px 2.5px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      .el-menu {
        color: #000;
      }
      &.El-transparentHeader {
        box-shadow: none;
        background: none;
        .el-menu {
          color: #fff;
        }
      }
    }
  }
}

// menu css start
@media (min-width: 768px) {
  .headerMain {
    .el-headerToolbar {
      min-height: 82px;
    }
    .el-MenuWeb {
      .MuiListItemButton-root {
        background: none;
        &:hover {
          background: none;
        }
      }
    }
    .sticky-header {
      .el-headerToolbar {
        min-height: 66px;
      }
      .El-LogedIn {
        margin-right: 0px;
      }
      .MuiListItem-root {
        margin-right: $spacing-md;
        &.el-contactNum {
          font-size: $font-size-md;
          .MuiButtonBase-root {
            color: $primary-white;
            border-color: $gray-dark;
            background: $gray-dark;
          }
        }
      }
    }
  }

  .eliList {
    .MuiListItem-root {
      margin-right: $spacing-md;
      &.el-contactNum {
        font-size: $font-size-md;
        .MuiButtonBase-root {
          // background: rgba(0, 0, 0, 0.4);
          border: 1px solid #f2f2f2;
          padding-right: $spacing-xxs;
          padding-left: $spacing-xxs;
          svg {
            max-width: 18px;
          }
          &:before {
            display: none;
          }
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
      color: $primary-white;
      white-space: nowrap;
      padding: 2px 6px;
      ul li:last-child {
        margin: 0px;
      }
      .MuiListItemText-root {
        margin: 0px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: $font-size-default;
        span {
          font-weight: $font-weight-bold;
          font-size: $font-size-default;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        background-color: $color-primary;
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
      }
      &:hover,
      &.menuActive {
        background: rgba(0, 0, 0, 0.2);
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }
    .el-login {
      .MuiButtonBase-root {
        background: $primary-white;
        color: #000;
        padding: 3px 14px;
        &:before {
          display: none;
        }
        span {
          font-weight: $font-weight-bold;
          font-size: $font-size-default;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .el-mobileHeaderDrawer {
    .MuiDrawer-paper {
      background: rgba(0, 0, 0, 0.8);
    }
    .closeDrawer {
      color: $primary-white;
      position: relative;
      text-align: right;
      display: block;
      padding: $spacing-lg 36px 0px $spacing-sm;
    }
  }
  .eliList {
    li {
      border-bottom: none;
      margin: 0px;
      .menuActive span {
        color: $color-primary;
      }
      a {
      }
      &.el-contactNum a {
        font-size: $font-size-lg;
        text-align: left;
        /* display: inline-block; */
        margin-left: 21px;
        margin-top: $spacing-md;
        border-radius: $border-radius;
        margin-bottom: $spacing-md;
        padding-left: $spacing-xxs;
        padding-right: $spacing-xs;
        border: 1px solid #a6a6a6;
        color: #b3b3b3;
        max-width: fit-content;
      }
      span {
        text-align: left;
        font-size: 15px;
        color: #a6a6a6;
        text-transform: uppercase;
      }
    }
  }
}
