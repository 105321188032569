@import '@styles/_variables.scss';
.rdrMonths {
  display: flex;
  margin-top: -43px;
}
.rdrStartEdge {
  background: $primary-black !important;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  // border-top-left-radius: 0.5em;
  // border-bottom-left-radius: 0.5em;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid $primary-black;
}
.rdrMonthAndYearWrapper {
  align-items: center;
  padding-top: 0px;
  min-width: 543px;
  margin: 0 auto;
  height: auto;
}
.rdrMonthName {
  text-align: center;
  font-weight: $font-weight-bold;
  color: $primary-black;
  padding: 0.65em;
  font-size: $font-size-md;
  line-height: 33px;
}
.rdrWeekDay {
  font-weight: $font-weight-bold;
  line-height: 2.667em;
  color: $primary-black;
  font-size: $font-size-default;
}
.rdrMonthAndYearPickers {
  display: none;
}
.rdrDateDisplayWrapper {
  display: none;
}
.rdrEndEdge {
  background: $primary-black !important;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 50% !important;
  z-index: 1;
  border: 1px solid $primary-black;
}
.rdrInRange {
  top: 0px;
  left: -18px;
  bottom: 0px;
  right: -18px;
  background-color: $gray-border;
  z-index: 0;
  border-top-right-radius: 0em !important;
  border-bottom-right-radius: 0em !important;
}
.strikethrough {
  width: 18px;
  position: absolute;
  height: 1px;
  background: #bababa;
  top: 50%;
  left: 50%;
  margin-left: -9px;
}
.rdrInRange ~ span.rdrDayNumber {
  span {
    color: $gray-extra-dark !important;
    font-weight: 800;
  }
}
.rdrDayPassive {
  visibility: hidden;
}
.rdrDayToday.rdrDayPassive .rdrDayNumber {
  border: none;
}
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.rdrDayNumber {
  z-index: 2;
  span {
    display: block;
  }
}
.rdrDayToday {
  .rdrDayNumber {
    background-color: $primary-white !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border: 1px solid $primary-black;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    span {
      color: $primary-black !important;
    }
    span:after {
      display: none;
    }
  }
}

.rdrDay {
  height: 40px;
  margin-bottom: $spacing-3xs;
  & > span {
    height: 40px;
    width: 40px;
  }
}
.rdrDayDisabled {
  background: none !important;
  border: none !important;
}
.rdrDayNumber span {
  font-size: $font-size-md;
  color: $gray-extra-dark;
  height: auto !important;
  width: auto !important;
}
.rdrInRange {
  height: auto !important;
  width: auto !important;
  left: -27px;
}
.rdrDayNumber {
  top: 0px;
}
.rdrDayEndPreview {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border: none;
}

.rdrDayStartPreview {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border: none;
}
.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  filter: brightness(0) invert(1);
}
.rdrDayInPreview {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.el-dateRangeModal {
  .MuiMenu-paper {
    width: 720px;
    border-radius: $spacing-xs;
  }
}
.EL-date-picker-modal {
  width: 680px;
  padding: 0 0 0 $spacing-md;
  .MuiDialogContent-root {
    padding: 0px;
  }
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;

    padding: $spacing-sm 0px $space-22px 0px;
    button {
      color: $blue-light;
      text-decoration: underline;
      font-weight: $font-weight-bold;
      text-transform: capitalize;
    }
  }
  .rdrCalendarWrapper {
    width: 100%;
    .rdrMonths {
      width: 100%;
      .rdrMonth {
        width: 50%;
        .rdrWeekDays {
          position: relative;
          left: -4px;
        }
      }
    }
  }
  .EL-calenderAction {
    justify-content: space-between;
  }
  .EL-selectedNights {
    .MuiAlert-root {
      margin-right: $spacing-sm;
      padding: 0 $spacing-xxs;
      color: $color-primary;
      margin-bottom: 8px;
      margin-left: 8px;
      .MuiAlert-icon {
        color: $color-primary;
        margin-right: 4px;
      }
    }
  }
  .EL-calenderButton {
    button {
      width: 196px;
      background: $primary-black;
      border-radius: $border-radius-xl;
      text-transform: none !important;
      font-weight: $font-weight-bold;
      height: 48px;
      &.Mui-disabled {
        background: $gray-pastel;
      }
    }
  }
}

.rdrNextButton {
  width: 32px;
  height: 32px;
  background-color: $gray-border;
  display: 'flex';
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.rdrNextButton i {
  border: solid $primary-black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 3px;
  margin: 0px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 3px;
  &:before {
    content: '';
    height: 22px;
    width: 22px;
    position: absolute;
    left: -5px;
    top: -6px;
  }
}

.rdrPprevButton {
  width: 32px;
  height: 32px;
  background-color: $gray-border;
  display: 'flex';
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.rdrPprevButton i {
  border: solid $primary-black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 4px;
  &:before {
    content: '';
    height: 22px;
    width: 22px;
    position: absolute;
    left: -5px;
    top: -6px;
  }
}
.rdrDayStartOfMonth.rdrStartEdge,
.rdrDayStartOfWeek.rdrStartEdge {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfMonth .rdrInRange {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
@media (max-width: 767px) {
  .rdrDay {
    margin-bottom: $spacing-3xs;
  }
  .rdrInRange {
    left: -36px !important;
  }
}
