.EL-metaportDetail {
  padding-bottom: 8px;
  margin-top: 16px;
  @media (max-width: 768px) {
    margin-top: 8px;
    padding: 0px;
  }
}
.EL-matterportButton {
  background: #eaf0fd;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: left;
  justify-content: space-between;
  &:hover {
    background: #e1ebff;
  }

  h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
  }
  .MuiTypography-span {
    color: #666;
  }
  .EL-matterportTitle {
    margin-left: 12px;
    color: #000;
    text-transform: capitalize;
  }
  .MuiButton-endIcon {
    svg {
      font-size: 34px;
      color: #000;
    }
  }
  @media (max-width: 768px) {
    padding: 6px 8px;

    h6 {
      font-size: 14px;
    }
    .EL-matterportTitle {
      margin-left: 8px;
    }
  }
}
.EL-matterportDialog {
  .EL-matterportDialogContent {
    padding: 0px;
    overflow: hidden;
    height: 650px;
  }
  .EL-matterportDialogContentInner {
    height: 650px;
    padding: 16px;
    overflow: hidden;
    iframe {
      height: 620px;
      width: 100%;
    }
  }
  .El-matterportDialogClose {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: #000;
    color: #fff;
    opacity: 0.5;
  }
  .EL-matterportShare {
    position: absolute;
    right: 75px;
    bottom: 39px;
    background: #555;
    color: #fff;
    border-radius: 20px;
    padding: 6px 20px;
    text-transform: capitalize;
    svg {
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    .El-matterportDialogClose {
      right: 16px;
      top: 22px;
    }
    .EL-matterportShare {
      right: 16px;
      bottom: 28px;
      padding: 4px 20px;
    }
    .EL-matterportButton {
      padding: 12px 12px;
    }
    .EL-matterportDialogContent {
      height: 100%;
    }
    .MuiPaper-root {
      margin: 0px !important;
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      padding: 0px;
      .EL-matterportDialogContentInner {
        height: 100%;
        padding: 0px;
        iframe {
          height: 100% !important;
          width: 100% !important;
          margin-top: -1px;
        }
      }
    }
  }
}
