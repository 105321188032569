@import '@styles/_variables.scss';
.detailCheckinCheckoutMain {
  .detailCheckinCheckout {
    border: 1px solid $gray-pastel;
    padding: 0px 0px 0 0px;
    border-radius: $spacing-xs;
    margin: $spacing-sm 0px 15px 0px;
    .MuiTextField-root {
    }
    .detailCheckinCheckoutField {
      display: flex;
      margin: 0 0px 0px 0px;
      padding: 4px $spacing-xs;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 45%;
        width: 1px;
        height: $spacing-lg;
        background: #d4d4d4;
        top: 50%;
        margin-top: -12px;
      }
      .MuiTextField-root {
        .MuiFormLabel-root {
          left: -12px;
          color: $primary-black;
          font-weight: $font-weight-bold;
        }
        .MuiInputBase-root {
          background: none;
          &:after,
          &:before {
            display: none !important;
          }
          input {
            // color: $primary-black;
            font-weight: $font-weight-bold;
            font-size: $font-size-md;
            padding-left: 0px;
            color: $color-secondary;
          }
        }
      }
      .MuiFormLabel-filled {
        .El-calLabel {
          color: $primary-black;
          font-size: $font-size-lg;
        }
      }
      .El-calLabel {
        display: flex;
        align-items: center;
        color: #a1a1a1;
        font-weight: 400;
        svg {
          font-size: $font-size-xxl;
          color: #a1a1a1;
          margin-right: 6px;
        }
      }

      .MuiTextField-root {
        .MuiInput-root {
          padding-right: 10px;
          svg {
            font-size: $font-size-md;
            margin-right: $spacing-xxs;
          }
          &:before {
            border: none;
          }
        }
        &:before {
          border: none;
        }
        &:first-child {
          .MuiInput-root {
            border-right: 1px solid $gray-pastel;
          }
        }
      }
    }
    .detailCheckinCheckoutButton {
      border-top: 1px solid $gray-pastel;
      position: relative;
      .El-addGuestButton {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: $spacing-sm 0px 18px 0px;
        padding: 0px $spacing-xs;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .El-addGuestButtonInner {
          display: flex;
          align-items: center;
          // color: $primary-black;
          font-size: $font-size-md;
          font-weight: $font-weight-bold;
          text-transform: initial;
          svg {
            margin-right: 6px;
            color: #999;
          }
        }
      }
    }
  }

  .EL-CheckAvailability {
    border-radius: $border-radius-xl;
    margin-bottom: $spacing-sm;
    font-size: $font-size-md;
    height: 48px;
    & .MuiCircularProgress-root {
      height: $space-22px !important;
      width: $space-22px !important;
    }
  }
}
