.EL-backToTop {
  position: fixed;
  bottom: 22px;
  left: 50%;
  margin-left: -45px;
  z-index: 999;
  button {
    background: #f0f0f0;
    font-size: 12px;
    padding-left: 12px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
    &:hover {
      background: #fff;
    }
    svg {
      font-size: 20px;
      margin-left: 2px;
    }
  }
}
