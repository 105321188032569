@import '@styles/_variables.scss';
.mobileDatePickerModal {
  z-index: 9999 !important;
  & > .MuiPaper-elevation {
    -webkit-border-top-left-radius: $spacing-xs;
    -webkit-border-top-right-radius: $spacing-xs;
    -moz-border-radius-topleft: $spacing-xs;
    -moz-border-radius-topright: $spacing-xs;
    border-top-left-radius: $spacing-xs;
    border-top-right-radius: $spacing-xs;
    position: fixed;
  }
  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xxs;
    .MuiTypography-root {
      color: $primary-black;
      font-weight: $font-weight-bold;
    }
    button {
      padding: 0px;
      width: auto;
      min-width: inherit;
      color: $blue-light;
      text-transform: capitalize;
      font-weight: $font-weight-bold;
      &.icon-button {
        color: $primary-black !important;
      }
    }
  }
  .MuiDialogContent-root {
    max-height: 54vh;
    overflow: auto;
    padding: 0px 0 $spacing-xxs 0px;
    .rdrMonthAndYearWrapper {
      display: none;
    }
    .rdrMonths {
      margin-top: 40px;
    }
    .rdrMonth {
      .rdrMonthName {
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
      }
      .rdrWeekDays {
        padding: 0;
        position: absolute;
        top: 40px;
        z-index: 9;
        width: 100%;
        background: $primary-white;
        left: -6px;
        border-bottom: 1px solid #0000001a;
        .rdrWeekDay {
          border-bottom: 1px solid #e8e8e8;
          font-size: $font-size-md;
          color: $primary-black;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
  .MuiDialogActions-root {
    // box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    //   0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px $spacing-xxs 0px rgba(0, 0, 0, 0.12);
    justify-content: space-between;
    padding: $spacing-sm;
    z-index: 2;
    &.El-action-container {
      padding: 0px !important;
    }
    .El-actions {
      display: flex;
      flex-direction: column;
      width: 100%;

      .El-selectedDates {
        display: flex;
        align-items: center;
        border-top: 1px solid #e1e1e1;
        .EL-dateContainer {
          width: 50%;
          padding: $spacing-xxs $spacing-sm;
          .MuiTypography-root {
            &.date-header {
              color: $primary-black;
            }
            &.date-selected {
              color: $primary-black;
              font-weight: $font-weight-bold;
            }
            &.date-empty {
              color: $gray-pastel;
            }
          }
        }
        .EL-dateContainer:nth-child(2) {
          border-left: 2px solid #e1e1e1;
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-xxs $spacing-xxs;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.125) !important;
        z-index: 3;
        button {
          text-transform: none !important;
          &.clear-button {
            background: transparent;
            border: none;
            color: $blue-light;
            text-decoration: underline;
            text-align: left;
            &.Mui-disabled {
              color: $gray-medium-dark;
            }
          }
          &.guest-button {
            background-color: $primary-black;
            border-radius: $border-radius-xl;
            min-width: 200px;
            font-size: 1em;
            height: 46px;
            font-weight: $font-weight-bold;
            &.Mui-disabled {
              background-color: $gray-pastel;
            }
          }
        }
      }
    }
    .el-nights {
      .MuiTypography-button {
        margin: 0px;
        color: $primary-black !important;
        font-weight: $font-weight-bold;
        line-height: $spacing-md;
        font-size: 1em;
        text-transform: inherit;
      }
      .MuiTypography-caption {
        margin: 0px;
        line-height: inherit;
      }
    }

    button {
      text-transform: none !important;
      &.continue-button {
        background-color: $primary-black;
        border-radius: $border-radius-xl;
        min-width: 200px;
        font-size: 1em;
        height: 46px;
        font-weight: $font-weight-bold;
        &.Mui-disabled {
          background-color: $gray-pastel;
        }
      }
    }
  }
  .rdrCalendarWrapper {
    width: 100%;
    .rdrMonth {
      width: 100%;
      padding: 0px 0 $spacing-sm 0px !important;
    }
  }
  .EL-calenderError {
    position: absolute;
    bottom: 8rem;
    width: 100%;
    z-index: 5;
    .MuiAlert-icon {
      margin-right: 4px;
    }
    .MuiPaper-root {
      background: #fff2ee;
      padding: 2px $spacing-sm;
      border-radius: 0px;
    }
    & svg {
      color: $color-primary;
    }
    .MuiAlert-message {
      color: $color-primary;
    }
    &.El-alert {
      position: relative;
      bottom: inherit;
      .MuiAlert-root {
        padding-top: 0px;
        padding-bottom: 0px;
        border: none;
        padding-right: 0px;
        color: $color-primary;
        background: none;
        svg {
          color: $color-primary;
        }
      }
      &.El-yalloElert {
        .MuiAlert-root {
          svg {
            color: $color-warning;
          }
        }
        .MuiAlert-message {
          color: $color-warning;
          svg {
            color: $color-warning;
          }
        }
      }
      // @media (max-width: 767px) {
      //   position: absolute;
      //   bottom: 69px;
      // }
    }
  }
  .el-dialog-header {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    .El-geuestMail {
      .MuiDialogContent-root {
        padding: 8px 0 0px 0px;
      }
    }
  }
}
