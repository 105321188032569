.EL-bookingnotBlongModal {
  .MuiDialog-paper {
    border-radius: 12px;
  }
  .EL-bookingnotBlongContent {
    padding: 40px 70px;
    text-align: center;
    max-width: 270px;
    h6 {
      padding: 16px 0px;
      color: #000;
      font-size: 22px;
      line-height: 28px;
    }
    button,
    a {
      margin-top: 8px;
      background: #000;
      border-radius: 30px;
    }
  }
  @media (max-width: 768px) {
    .MuiDialog-container {
      align-items: end;
      .EL-bookingnotBlongContent {
        padding: 40px 20px;
        text-align: center;
        max-width: inherit;
      }
      .MuiDialog-paper {
        border-radius: 0px;
        margin: 0px;
      }
    }
  }
}
