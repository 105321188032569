@import '@styles/_variables.scss';
.El-guestModal {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-top: 12px;
  max-width: 360px;
  border-radius: 0.75rem !important;
  position: absolute;
  top: 45px;
  left: 0px;
  background: #fff;
  z-index: 9;
  display: none;
  &.EL-guestModalOpen {
    display: block;
  }
  .MuiPaper-rounded {
    border-radius: $spacing-xs !important;
  }
  .MuiDialogContent-root {
    padding-top: 8px !important;
  }
  .MuiDialogContent-root {
    padding-top: 8px !important;
  }
  h6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    padding: $spacing-xs $spacing-sm;

    button {
      font-size: $font-size-default;
      font-weight: $font-weight-bold;
    }
  }

  .EL-GuestDone {
    width: 100%;
    background: $primary-black !important;
    border-radius: $border-radius-xl;
    margin-top: $spacing-sm;
    margin-top: 8px;
    text-transform: capitalize;
    height: 48px;
  }
  .EL-calenderError {
    &.El-alert {
      // position: absolute;
      // bottom: 82px;
      .MuiAlert-root {
        padding-top: 0px;
        padding-bottom: 0px;
        border: none;
        padding-right: 0px;
        color: $color-primary;
        padding-left: 0px;
        svg {
          color: $color-primary;
        }
      }
      &.El-yalloElert {
        .MuiAlert-root {
          color: $color-warning !important;
          svg {
            color: $color-warning;
          }
        }
      }
    }
  }
}
.El-geuestMail {
  min-width: 355px;
  .MuiMenuItem-gutters {
    padding: $spacing-xs $spacing-xxs;
    cursor: inherit;
    &:hover {
      background: none;
    }
    @media (max-width: 767px) {
      padding: $spacing-xs $space-22px;
    }
  }
  h2.MuiDialogTitle-root {
    padding-top: $spacing-xxs;
    padding-bottom: 0px;
    button {
      color: $blue-light;
      padding-right: $spacing-xxs;
    }
    @media (max-width: 767px) {
      padding-top: $spacing-sm;
      padding-bottom: $spacing-xs;
    }
  }
  .MuiTypography-h6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    margin: 0px;
    padding-top: 0px;
    padding-left: $spacing-xxs;
    padding-right: $spacing-xxs;
  }
  button {
    font-weight: $font-weight-bold;
  }
  .MuiTypography-body1 {
    font-weight: $font-weight-bold;
    font-size: $font-size-md;
    div {
      font-size: $font-size-default;
      color: #00000066;
      font-weight: 400;
    }
  }
  .El-geistButtonGroup {
    border: 1px solid $gray-pastel;
    border-radius: $border-radius-xl;
    overflow: hidden;
    button {
      border: none;
      color: $gray-extra-dark;
      background-color: none;
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      min-width: 34px;
      padding: 6px 0px;
      @media (max-width: 767px) {
        min-width: 40px;
        font-size: $font-size-lg;
      }
      svg {
        color: $blue-light;
      }

      &.Mui-disabled {
        color: $gray-pastel;
        svg {
          color: $gray-pastel;
        }
      }
    }
  }
  // .MuiAlert-standard {
  //   background: none;
  //   color: #f97316;
  //   .MuiAlert-icon {
  //     margin-right: 6px;
  //   }
  // }
}
