.EL-CouponSwiper {
  &.swiper {
    overflow: inherit;
  }
  .swiper-wrapper {
    overflow: inherit;
    padding: 0 16px 8px 16px;
    // width: 100%;
    .EL-CouponCard {
      .MuiCardHeader-content {
        overflow: hidden;
        .MuiTypography-root {
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }

      .MuiCardContent-root {
        .MuiTypography-body2 {
          min-height: 48px;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 0px;
      .swiper-slide {
        max-width: 90%;
      }
      .EL-CouponCard {
        .MuiCardContent-root {
          .MuiTypography-body2 {
            min-height: 48px;
          }
        }
      }
    }
  }
  .MuiPaper-root {
    box-shadow: 0px 1px 4px 0px #00000026;
    border-radius: 8px;
  }
  .coupon-img-next,
  .coupon-img-prev {
    position: absolute;
    top: 50%;
    z-index: 9;
    background: #1c1b1f;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.swiper-button-disabled {
      background: #ccc;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .coupon-img-next {
    right: -8px;
  }
  .coupon-img-prev {
    left: 0px;
  }
}

.EL-applyCouponField {
  &.MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .MuiInputBase-root {
    padding: 0px;
    overflow: hidden;
    & fieldset {
      display: none;
      border-color: #c2c2c2 !important;
    }
    &.Mui-focused {
      & fieldset {
        border-color: #c2c2c2;
        border-width: 1px;
      }
    }
    .MuiInputBase-input {
      border: 1px solid #c2c2c2;
      height: auto;
      padding: 12px 16px;
      border-radius: 4px;
      border-right: none;
      background: #fff;
      @media (max-width: 767px) {
        padding: 8px 16px;
      }
    }
    button {
      border-radius: 0px;
      min-width: 100px;
      height: 49px;
      cursor: pointer;
      margin-left: -4px;
      background-color: #e73c33;
      &:hover {
        background-color: rgb(243, 62, 62);
      }
      &.Mui-disabled {
        background: #c2c2c2;
        color: #fff;
      }
      @media (max-width: 767px) {
        min-width: 90px;
        height: 41px;
      }
    }
  }
}

.EL-couponField {
  background: #fff;
  padding: 12px 16px;
  position: sticky;
  top: 61px;
  z-index: 1;
  .MuiFormControl-root {
    margin: 0px;
  }
}
