$TRBL: top, right, bottom, left;

//$color-primary: #007bff !default;
//$color-secondary: #6c757d !default;
$color-success: #28a745 !default;
//$color-error: #dc3545 !default;
$color-warning: #fd9e11 !default;
//$color-info: #17a2b8 !default;
$color-dark: #343a40 !default;
$color-light: #f8f9fa !default;

// ------------- COLORS ------------- //

$primary-black: #000000;
$primary-white: #ffffff;
$primary-background: #f6f6fb;

$color-ghost-white: #f7f5ff;
$color-table-header: #333443;

// grayScale
$gray-extra-dark: #222;
$gray-extra-dark-1: #2219;
$gray-dark: #333;
$gray-light-1: #efefef;
$gray-medium-dark: #666;
$gray-medium: #969696;
$gray-medium-light: #e8e8e8;
$gray-light: #f8f8f8;
$gray-extra-light: #ececf3;
$gray-iron: #d5d5e2;
$gray-clan: #c0ccda;
$gray-menu: #888;
$gray-pastel: #ccc;
$gray-border: #dcdcdc;
$gray-500: #1c154c;
$gray-transparent: rgba(0, 0, 0, 0.08);
$gray-transparent-light: rgba(225, 225, 225, 0.24);
$gray-input-border: #d9d9d9;
$gray-dark-1: #f9f9f9;

// Primary
$color-primary: #ef4444;
$color-primary-dark: #ef4444;
$color-primary-medium: #ffb183;
$color-primary-light: #ffeadd;


// Secondary
$color-secondary: #364d59;
$color-secondary-dark: #4232b0;
$color-secondary-medium: #5d49e4;
$color-secondary-light: #d3cdff;

// blueScale
$blue-light: #2563eb;
$color-secondary-dark: #2563eb;
$color-secondary-medium: #5d49e4;
$color-secondary-light: #d3cdff;

// Highlight
$color-highlights: #ffb900;
$color-highlights-dark: #f5b200;
$color-highlights-medium: #f9db8b;
$color-highlights-light: #faefd1;

// Gradient
$color-gradient: linear-gradient(273.58deg, #ff5f00 0%, #ff9a5a 100%);
$gray-gradient: linear-gradient(273.58deg, #ddd 0%, #e0e0e0 100%);
$gradient-light: #ff9a5a;

$color-error: #b80000;
$color-info: #616d78;

// Success & Warning
$color-success-light: #007e33;
$color-success-dark: #0cb551;
$color-success-background: #d4f7e2;

$color-warning-light: #b80000;
$color-warning-dark: #f64c4c;
$color-warning-background: #fceeee;

// Brown scale
$color-extra-light-brown: #fffcf5;

//Blue scale
$color-aqua-blue: rgba(181, 234, 215, 0.47);
$color-dark-blue: #323444;
$color-light-blue: #1890ff;
$color-extra-light-blue: #e2dff8;
$color-purply-blue: #f4f2ff;
$color-dark-blue-1: #1c154c;
$color-extra-light-blue-1: #eeebff;
$chip-blue: #7b61ff;
// Red scale
$red-extra-light: #feefe4;
$color-hot-pink: rgba(254, 239, 228, 0.25);

// Orange scale
$orange-extra-light-3: #fff5ea;
$orange-extra-light-2: #ffefe6;
$orange-extra-light-1: #ffd877;
$orange-light: #ffa773;
$orange-extra-light: rgba(250, 210, 108, 0.37);
$orange-light-1: #fff8f3;
$orange-chip-color: #ffe9d0;
$orange-light-2: #f9db8b;
$orange-light-3: #fff7e3;

$color-box-shadow: rgba(30, 20, 100, 0.1);
$color-box-shadow-1: rgba(34, 34, 34, 0.15);
$color-box-shadow-2: rgba(30, 20, 100, 0.25);
$color-box-shadow-3: rgba(34, 34, 34, 0.7);
$color-box-shadow-light: rgba(0, 0, 0, 0.1);
$color-transparent: transparent;

// violet
$color-violet: #4232b0;
$input-border-violet: #342870;
$light-violet: #f5f3ff;

//Spacing
$space-zero: 0;
$space-8px: 8px;
$space: 1rem; // 16px //
$spacing-3xs: $space * 0.25; // 4px //
$spacing-xxs: $space * 0.5; // 8px //
$spacing-xs: $space * 0.75; // 12px //
$spacing-sm: $space; // 16px //
$spacing-md: $space * 1.25; // 20px //
$spacing-lg: $space * 1.5; // 24px //
$spacing-xl: $space * 1.75; // 28px //
$spacing-xxl: $space * 2; // 32px //
$spacing-3xl: $space * 2.25; // 36px //
$spacing-4xl: $space * 2.5; // 40px //
$spacing-5xl: $space * 2.75; // 44px //
$spacing-6xl: $space * 3; // 48px //
$spacing-7xl: $space * 3.25; // 52px //
$spacing-8xl: $space * 3.5; // 56px //
$spacing-9xl: $space * 3.75; // 60px //
$spacing-10xl: $space * 4; // 64px //
$spacing-11xl: $space * 4.25; // 68px //
$spacing-12xl: $space * 4.5; // 72px //
$spacing-13xl: $space * 4.75; // 76px //
$spacing-14xl: $space * 5; // 80px //

$space-22px: 22px;

// ------------- BORDER RADIUS ------------- //
$border-radius: 4px;
$border-radius-xl: $space * 1.5;
$border-radius-base: $space * 0.25;
$border-default-light: 1px solid $gray-border;
$border-default: 1px solid #ccc;
$border-thick: 1.5px solid $gray-border;
$border-default-transparent: 1px solid transparent;

// ------------- BOX SHADOW ------------- //
$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
$box-shadow-light: 9px 0 12px -2px rgba(30, 20, 100, 6%);
$box-shadow-thick: 0px 4px 12px rgba(30, 20, 100, 0.1);
$box-shadow-table-column: 3px 0px 6px rgba(30, 20, 100, 0.1) inset;
$box-shadow-table: 0px 4px 20px rgba(30, 20, 100, 0.1);

// dotted svg line
$dotted-line: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239F9F9FFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

// font size
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-default: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-xxl: 22px;
$font-size-3xl: 24px;
$font-size-4xl: 26px;
$font-size-5xl: 28px;
$font-size-6xl: 30px;
$font-size-7xl: 32px;
$font-size-8xl: 34px;
$font-size-9xl: 36px;

// font Wight
$font-weight-bold: 600;
$font-weight-thin: 100;
