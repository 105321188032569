@import '@styles/_variables.scss';
.EL-caontactUsPDP {
  // margin-bottom: $spacing-sm;
  margin-bottom: 0px;
  button {
    white-space: nowrap;
    margin: 0px;
    border-radius: $border-radius-xl;
    width: 48%;
    height: 48px;
    font-size: $font-size-md;
    text-transform: capitalize;
    font-weight: $font-weight-bold;
    & svg {
      color: $primary-white;
    }
    &.EL-BookNow {
      margin: 0px;
      min-width: 48%;
      border-radius: $border-radius-xl;
      font-size: $font-size-md;
      background-color: $primary-black;
      height: 48px;
      margin-left: $spacing-sm;
      color: $primary-white;
      & .MuiCircularProgress-root {
        height: $space-22px !important;
        width: $space-22px !important;
      }
    }
    &.EL-CheckAvailability {
      width: 100%;
      background-color: $color-primary;
      color: $primary-white;
      margin-bottom: 0px;
    }
    &.El-whatsAppDetail {
      width: 100%;
      background: #40b64d;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
    max-width: 220px;
    button {
      height: 42px;

      &.EL-BookNow {
        height: 46px;
        border-radius: $border-radius-xl;
        background-color: $primary-black;
        max-width: 200px;
        white-space: nowrap;
        margin: 0px;
        min-width: 152px;
        font-size: 14px;
        &.Mui-disabled {
          background-color: $primary-black;
          color: $primary-white;
        }
        .MuiButton-startIcon {
          .MuiCircularProgress-svg {
            color: $primary-white;
          }
        }
        & .MuiCircularProgress-root {
          height: 18px !important;
          width: 18px !important;
        }
      }
      &.El-whatsAppDetail {
        background: none;
        padding: 0px;
        width: auto;

        span.MuiButton-startIcon {
          border-radius: 50% !important;
          width: 46px !important;
          padding: 0px !important;
          height: 46px !important;
          text-align: center;
          display: inline-table;
          max-width: 46px !important;
          display: flex;
          align-items: center;
          margin-right: 0px;
          background: #40b64d;
          color: $primary-white !important;
          justify-content: space-evenly;
        }
      }
    }
  }
}
.EL-contactUsPDP {
  margin-top: $spacing-xxs;
  margin-bottom: $spacing-sm;
  & .MuiChip-root {
    width: 100%;
    height: 48px;
    font-size: $font-size-md;
    border-radius: $border-radius-xl;
    margin-top: $spacing-sm;
    b {
      color: #000;
    }
  }
}
.El-bookAtSptlit {
  background-color: #ffeccf !important;
  margin-bottom: 16px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 8px !important;
  .MuiAlert-icon {
    padding: 0px;
    display: flex;
    align-items: center;
    max-width: 21px;
  }
  .MuiAlert-message {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .EL-cardSptlit {
    padding-top: 6px;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    position: fixed;
    bottom: 65px;
    left: 0px;
    border-radius: 0px !important;
    z-index: 1;

    .MuiAlert-message {
      font-size: 12px;
    }
  }
}

.EL-minStayAlert {
  margin-bottom: 16px;
  padding: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
  border-radius: 8px !important;
  .MuiAlert-root {
    padding: 10px !important;
  }
  .MuiAlert-icon {
    display: flex;
    align-items: center;
    padding: 0px;
    color: #ef4444 !important;
  }
  .MuiAlert-message {
    padding-top: 4px;
    padding-bottom: 4px;
    color: #ef4444;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    position: fixed;
    bottom: 65px;
    border-radius: 0px !important;
    left: 0px;
    z-index: 1;
  }
}

.EL-maxDayError {
  margin-bottom: $spacing-sm;
  width: 100%;
  .MuiPaper-root {
    background: #cceee2;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: $spacing-xxs;
    padding-left: $spacing-xs;
    padding-right: $spacing-xs;
  }

  .MuiAlert-message {
    color: #000;
    font-size: $font-size-default;
  }
  .MuiAlert-icon {
    & svg {
      color: #f97316;
    }
    & img {
      max-width: 30px;
    }
  }

  @media (max-width: 767px) {
    position: absolute;
    bottom: 65px;
    left: 0px;
    margin-bottom: 0px;
    .MuiAlert-message {
      font-size: $font-size-sm;
      display: flex;
      align-items: center;
    }
    .MuiPaper-root {
      border-radius: 0px;
    }
  }
}
