.EL-breadcrumbs {
  .MuiBreadcrumbs-li {
    a {
      color: #666;
    }
    p {
      color: #444;
    }
  }
}
