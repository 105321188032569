@import '@styles/_variables.scss';
.EL-wallet_accordion {
  background-color: #fff2d5 !important;
  box-shadow: none !important;
  border: 0;
  font-size: 12px;
  border-radius: 8px !important;

  .EL-AccordionSummary {
    min-height: 48px !important;
  }

  .EL-AccordionSummary .Mui-expanded {
    margin: 12px 0;
    // min-height: 48px;
  }
  .highlightMessage {
    color: #ff4e4a;
    font-size: 12px;
    margin-top: 5px;
  }
}

.EL-walletBox {
  .MuiPaper-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff2d5 !important;
    border-radius: 8px !important;
    margin-top: 16px;
  }
  .EL-walletInner {
    display: flex;
    align-items: center;
    .EL-walletInner-2 {
      margin-left: 8px;
    }
  }
}

.EL-walletInner {
  align-items: center;
}

.EL-walletInner-2S svg {
  margin-bottom: -4px;
  margin-left: 2px;
  margin-right: 2px;
  width: 17px;
  height: 17px;
}

.EL-walletInner-2S span {
  font-size: 14px;
  font-weight: 600;
}

.EL-walletInner-2 span {
  font-size: 16px !important;
  color: #000000de !important;
  font-weight: 600 !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

span.EL-walletOnthisbooking {
  font-weight: 400 !important;
  color: #0000008a !important;
  font-size: 14px !important;
}

.EL-walletInner-2 svg {
  margin-bottom: -5px;
  margin-left: 2px;
  margin-right: 2px;
  height: 20px;
  width: 20px;
}

.EL-walletInner {
  display: flex;
}

// .EL-walletBox .MuiTooltip-tooltip {
//   border-radius: 12px !important;
//   background: #36312c !important;
//   font-weight: 400 !important;
//   padding: 16px 16px !important;
//   left: -15px;
//   font-size: 12px !important;
//   max-width: 100% !important;
// }

.EL-walletBox {
  .MuiTooltip-arrow {
    left: -15px !important;
  }

  .MuiTooltip-arrow::before {
    background: #36312c !important;
  }
}

span.EL-walletOnthisbooking {
  font-size: 12px !important;
}

.EL-BookingDetailDiv {
  .MuiAccordionSummary-expandIconWrapper svg {
    color: #1c1b1f;
  }
  hr.EL-Line {
    // border: 1px solid #0000001f;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .MuiAccordionDetails-root {
    padding-top: 0 !important;
  }
  .EL-walletInner-2S {
    margin-left: 8px;
  }
}

.EL-PaymentDetailPage hr.EL-Line {
  border: 1px solid #0000001f;
  margin-top: 0;
  margin-bottom: 10px;
}

.EL-walletInner-2S {
  margin-left: 8px;
}

.EL-walletBoxDef .MuiPaper-root {
  padding: 15px;
  display: flex;
  border: 0;
  box-shadow: none;
  margin-top: 5px;
  padding-bottom: 0;
  align-items: center;
}

.EL-RedeemClash {
  padding: 16px;
  border: 0 !important;
  display: flex;
  align-items: center;
}

.EL-RemainingBal {
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 2px;
    width: 20px;
    height: auto;
  }
}

.EL-RedeemClash {
  .EL-Redeem {
    width: 100%;
    .MuiChip-root {
      background-color: #fff2d5;
      width: 100%;
      border-radius: 4px;

      .MuiChip-label div {
        display: flex;
        align-items: center;
        color: #666155;
        .MuiTypography-root {
          font-size: 12px;
        }
      }
    }

    h6 {
      margin-bottom: 0;
      font-size: 14px;
      color: #000;
    }
  }
}
.EL-RedeemCheckbox {
  .MuiFormControlLabel-root {
    &.Mui-disabled {
      cursor: not-allowed;
    }
  }
}
.EL-RedeemCheckbox label {
  margin-right: 0;
}

.EL-WithoutField {
  flex-direction: column !important;
}

.EL-Redeem p {
  font-size: 14px;
  color: #0000008a;
  font-weight: 400;
}

.EL-RedeemRight {
  width: 40% !important;
  .MuiFormControl-root {
    width: 100%;
    display: flex;
    align-items: end;
    .MuiInputBase-root {
      padding-right: 5px;
      border-radius: 4px;
      background-color: #eceef4;
      text-align: center;
    }
  }
  .MuiInputAdornment-positionEnd {
    margin: 0 -4px 0 4px;
    min-width: 20px;
    .MuiCircularProgress-svg {
      width: inherit;
    }
    svg {
      width: 20px;
      height: auto;
    }
  }
  input {
    font-weight: 600;
    text-align: right;
    font-size: 16px;
    color: #000000e5;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .MuiInputBase-root .MuiInputAdornment-root::after {
    border: 1px solid #0000001f !important;
  }
}

.EL-walletInnerDef {
  span {
    font-size: 16px;
    font-weight: 600;
    svg {
      margin-bottom: -3px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}

.MuiTooltip-tooltip {
  border-radius: 12px !important;
  background: #36312c !important;
  font-weight: 400 !important;
  padding: 16px 16px !important;
  font-size: 12px !important;
  max-width: 100% !important;
}

.MuiTooltip-arrow::before {
  background: #36312c !important;
}

.EL-walletInnerDefwithout span {
  font-size: 14px;
  font-weight: 600;
  color: #000000de;
}

.EL-tooltipContentWithout {
  padding-top: 8px;
  margin-top: 8px;
  font-size: 12px;
  color: #0000008a;
  font-weight: 400;
  border-top: 1px solid #0000001f;
}

.EL-walletBoxWithoutTooltip {
  display: flex;
  background-color: #fff2d5;
  padding: 16px;
  width: 100%;
  margin-top: 15px;
  margin-top: 0;
}

.EL-walletBoxWithoutTooltip .MuiPaper-root {
  box-shadow: none;
  background-color: #fff2d5;
  margin-left: 10px;
}

.EL-walletInnerDefwithout svg {
  margin-bottom: -4px;
  margin-left: 3px;
  margin-right: 3px;
  width: 17px;
  height: 17px;
}

.EL-walletBoxDef .MuiPaper-root svg {
  color: #666666;
  margin-left: 2px;
  height: 20px;
  width: 20px;
  margin-bottom: -5px;
}

.EL-walletBoxDef .MuiPaper-root .EL-BookingPageTooltip {
  color: #666666;
  margin-left: 5px;
  height: 17px;
  width: 17px;
  margin-bottom: 0;
}

/* Custom styles to remove number input arrows */

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.bookingContainer .El-paymentDetail .MuiList-root .El-TotalPayble {
  border-bottom: 0 !important;
}

.EL-BookingDetailDiv .MuiAccordionDetails-root {
  padding-top: 0;
  padding-left: 30px;
}

.EL-MyBookingAccountPage .MuiPaper-root {
  padding-left: 1px;
}

@media (min-width: 481px) {
  .EL-walletBrTag {
    display: none;
  }
  .EL-walletBrTagOpp {
    display: block;
  }
  .EL-RedeemClash {
    padding: 1rem !important;
    border-radius: 1rem !important;
    margin-bottom: 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .EL-HideOnDesktop {
    display: none !important;
  }
  .EL-RedeemClash .EL-Redeem h6 {
    font-size: 16px;
  }
  .EL-Redeem p {
    font-size: 14px;
  }
  .EL-RedeemRight input {
    font-size: 20px;
  }
  .EL-RedeemRight {
    width: 35% !important;
  }

  .EL-walletBoxWithoutTooltip {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    margin-bottom: 15px;
  }
  span.EL-walletOnthisbooking {
    margin-left: 5px;
  }
  .EL-PaymentDetailPage span.EL-walletOnthisbooking {
    margin-left: 0;
  }
  .EL-PaymentDetailPage .MuiAccordionDetails-root {
    padding-top: 0;
    padding-left: 30px;
  }
  .EL-BookingDetailDiv span.EL-walletOnthisbooking {
    margin-left: 0;
  }
  .EL-wallet_accordion {
    margin-bottom: 15px;
    padding: 0 10px;
  }
  .EL-wallet_accordion::before {
    position: relative !important;
  }
  .EL-wallet_accordion .EL-AccordionSummary {
    padding: 0;
  }
  .EL-connfirmInfoField svg {
    padding: 0 !important;
    font-size: 20px;
    color: #666666;
  }
  .giftImage-custom-tooltip {
    right: -15px;
  }
  .giftImage-custom-tooltip-arrow {
    left: -15px !important;
  }
  .EL-hideSpaceHR {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .EL-walletBrTag {
    display: block;
  }
  .EL-walletBrTagOpp {
    display: none;
  }
  .EL-walletInner .EL-walletInner-2 {
    margin-left: 8px;
  }
  .EL-walletInnerDef span {
    font-size: 14px;
  }
  span.EL-walletOnthisbooking {
    margin-left: 5px;
  }
  .giftImage-custom-tooltip {
    left: 10px;
  }
  .giftImage-custom-tooltip-arrow {
    left: -10px !important;
  }
  .defaultView-custom-tooltip-arrow {
    left: 15px !important;
  }
  .defaultView-custom-tooltip {
    left: -15px !important;
  }
  .EL-connfirmInfoField svg {
    color: #666666;
    font-size: 14px !important;
    padding: 0px !important;
  }
  .EL-bookingDevider {
    &.EL-hideSpaceHR {
      margin: 0 !important;
    }
  }
  .EL-walletBoxDef .MuiPaper-root .EL-BookingPageTooltip {
    height: 13px;
    width: 13px;
  }
  .EL-ConfirmBookingPage span.EL-walletOnthisbooking {
    margin-left: 0;
  }
  .EL-RedeemClash {
    border-bottom: 6px solid #eceef4 !important;
  }
  .EL-ReviewStickAccordion hr.EL-Line {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .EL-ReviewStickAccordion .MuiAccordionDetails-root {
    padding-top: 0;
    padding-left: 46px;
  }
  .EL-ReviewStickAccordion .MuiAccordionSummary-expandIconWrapper svg {
    color: #1c1b1f;
  }
  .EL-ReviewStickAccordion .EL-wallet_accordion {
    border-radius: 0 !important;
  }
}
