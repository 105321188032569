@import '@styles/_variables.scss';
.topMediaSection {
  min-height: 28vh;
}
.propertyDetailContainer {
  li,
  p,
  div,
  span {
    // color: $color-secondary;
    // height: auto;
  }
}
.propertyDetailContainer {
  &.el-section {
    padding-top: 110px;
  }
  @media (max-width: 767px) {
    .topMediaSection {
      min-height: 200px;
    }
    &.el-section {
      padding-top: 77px;
      & ~ .elFooterMain {
        margin-bottom: 63px;
        & .MuiFab-circular {
          &.EL-floatAction {
            &.EL-whatsappIcon {
              bottom: 135px;
            }
            &.EL-CallIcon {
              bottom: 75px;
            }
          }
        }
      }
    }
  }

  p {
    font-size: $font-size-default;
    line-height: $space-22px;
    margin: 0px;
    iframe {
      border-radius: $spacing-xxs;
      overflow: hidden;
    }
  }
}
.leftSection {
  padding-right: $spacing-sm;
  h4 {
    color: $color-secondary;
    font-size: $font-size-lg;
    margin: 0px 0 $spacing-xxs 0px;
    font-weight: $font-weight-bold;
    button {
      svg {
        font-size: $font-size-xl;
      }
    }
  }

  .paperDetail {
    padding: $spacing-sm 0px $spacing-sm 0px;
    min-height: 100px;
  }

  // menu css start

  @media (max-width: 767px) {
    padding-right: 0px;
    .paperDetail {
      min-height: 140px;
      padding: $spacing-sm 0px $spacing-xs 0px;
      &.EL-detailOfferMobile {
        h6 {
          font-size: 16px;
        }
      }
    }
    h4 {
      font-weight: $font-weight-bold;
      font-size: $font-size-md;
    }
  }
}
.detail-right-stickBox {
  box-sizing: content-box;
  padding: 0px;
  margin: 0 0px 30px 15px;
  min-height: 200px;
  box-shadow: 0px 3px $spacing-xxs 0px #00000033;
  position: relative;
  background: $primary-white;
  border-radius: $spacing-xxs;
  transition: 0.5s;
  .EL-rightTop {
    transition: 0.5s;
    padding: 12px $spacing-md 16px $spacing-md;
  }
  .EL-rightBottom {
    background: #f7f7fa;
    padding: $spacing-md 16px;
  }
  .El-couponDetail {
    max-height: 300px;
    overflow: hidden;
    position: relative;
  }
  h6 {
    font-size: 18px;
    color: #272727;
  }
  .EL-dlCouponView {
    margin-top: 12px;
    text-decoration: underline;
    font-size: 14px;
    color: #458df8;
  }
}

.stickyRight {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 30px;
  will-change: transform; /* Add this line */
  transition: 0.5s;
  &.sticky {
    position: fixed;
    top: $spacing-md;
    max-width: 430px;
    transition: 0.3s;
  }
  h4 {
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-md;
    button {
      padding: 0px;
      margin-left: $spacing-3xs;
      svg {
        font-size: $font-size-md;
        color: #5b7583;
      }
    }
  }
  .el-detailCall {
    font-size: $font-size-xl;
    text-align: center;
    .MuiToolbar-root {
      min-height: 54px;
    }
  }
}
