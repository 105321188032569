@import '@styles/_variables.scss';
.MuiFab-circular {
  &.EL-floatAction {
    position: fixed;
    right: 40px;
    bottom: 28px;
    z-index: 9;
    background-color: #fefefe;
    width: 70px;
    height: 70px;
    &.EL-whatsappIcon {
      bottom: 28px;
    }
    &.EL-CallIcon {
      bottom: 88px;
    }
    &:hover {
      background-color: #fefefe;
    }

    svg {
      color: #25d366;
      font-size: 40px;
      height: 48px;
      width: 48px;
    }
    .ring-container {
      position: relative;
    }
    .MuiBadge-badge {
      margin-top: -24px;
      margin-right: -5px;
      background: #f00;
    }
    // animatedRing
    .ringring {
      border: 3px solid #f00;
      -webkit-border-radius: 30px;
      height: 6px;
      width: 6px;
      position: absolute;
      right: -11px;
      top: -30px;
      -webkit-animation: pulsate 1.4s ease-out;
      -webkit-animation-iteration-count: infinite;
      opacity: 0;
      box-sizing: content-box;
    }
    @-webkit-keyframes pulsate {
      0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        -webkit-transform: scale(1.3, 1.3);
        opacity: 0;
      }
    }
  }

  @media (max-width: 767px) {
    &.EL-floatAction {
      right: 16px;
      bottom: 16px;
      width: 44px;
      height: 44px;
      svg {
        font-size: 28px;
        height: 28px;
        width: 28px;
      }
      .MuiBadge-badge {
        margin-top: -14px;
        margin-right: -6px;
      }
      .ringring {
        height: 6px;
        width: 6px;
        right: -12px;
        top: -20px;
      }
    }
  }
}
