@import '@styles/_variables.scss';
.EL-confrimAction {
  margin: $spacing-sm 0px;
  height: 48px;
  button {
    border-radius: $border-radius-xl;
    &.MuiButton-outlinedInfo {
      color: $blue-light;
      border-color: $blue-light;
    }
    svg {
      margin-right: $spacing-3xs;
    }
  }
}
