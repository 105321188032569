.EL-CouponStatusDialog {
  .MuiPaper-rounded {
    border-radius: 12px;
    max-width: 460px;
  }
}
.El-couponSuccessLottie {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.EL-CouponStatusDrawer {
  .MuiDrawer-paper {
    -webkit-border-top-left-radius: 12px;
    -webkit-border-top-right-radius: 12px;
    -moz-border-radius-topleft: 12px;
    -moz-border-radius-topright: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}
.El-customDialogContent {
  text-align: center;
  padding: 40px 36px 28px 36px;
  min-width: 380px;
  display: grid;
  grid-row-gap: 20px;
  svg {
    min-height: 88px;
    min-width: 98px;
  }
  @media (max-width: 767px) {
    padding: 40px 0px 20px 0px;
    width: 90%;
    margin: 0 5%;
    min-width: inherit;
  }
  .El-couponFailed {
    h5 {
      font-size: 20px;
      color: #ed4441;
    }
  }
  h5 {
    font-size: 20px;
    color: #000;
    span {
      color: #3bb95e;
    }
  }
  svg {
    margin: 0 auto;
  }
  p {
    margin-bottom: 8px;
    color: #666;
  }

  button {
    background: #000;
    border-radius: 24px;
    height: 48px;
    cursor: pointer;
  }
}
// .EL-CouponStatusDialog {
//   width: 500px;
// }
