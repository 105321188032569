@import '@styles/_variables.scss';
.EL-CouponCard {
  padding: 16px 0px;

  .MuiCardHeader-root {
    padding-top: 0px;
    padding-bottom: 0px;

    button {
      text-transform: uppercase !important;
      padding: 0px;
      .MuiCircularProgress-root {
        height: 18px !important;
        width: 18px !important;
        margin-right: 4px;
      }
    }
    .MuiCardHeader-action {
      -webkit-align-self: inherit;
      .MuiBox-root {
        margin: 0px;
        padding: 0px;
        button {
          padding: 0px;
          width: auto;
          min-width: inherit;
        }
      }
    }
    .MuiCardHeader-content {
      .MuiTypography-root {
        font-size: 18px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // min-height: 51px;
        align-items: center;
        display: flex;
        ul,
        p {
          margin: 0px;
          padding: 0px;
          li {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
    .MuiCardHeader-avatar {
      .MuiAvatar-root {
        background-color: #eceef4;
        width: 36px;
        height: 36px;
        img {
          max-width: 80%;
          max-height: 80%;
        }
      }
    }
  }
  .MuiCardContent-root {
    padding-top: 0px;
    padding-bottom: 0px !important;
    h6 {
      color: #439459;
      border: 1px dashed #439459;
      display: inline-block;
      padding: 4px 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 600;
      background: #ebf4ee;
      margin-top: 12px;
    }
    .MuiDivider-root {
      margin: 12px 0px;
    }
    .MuiTypography-body1 {
      color: #e73c33;
      font-size: 14px;
      margin: 8px 0 0 0px;
    }
    .MuiTypography-body2 {
      padding: 12px 0 0 0px;
      border-top: 1px dashed #ccc;
      margin: 12px 0 0 0px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      p,
      ul,
      li {
        margin: 0px;
        padding: 0px;
      }
    }
  }
  .MuiCardActions-root {
    padding: 10px 0 0 0px;
    button {
      padding: 0px;
      color: #458df8;
      text-decoration: underline;
      font-weight: 400 !important;
    }
  }
  .EL-CouponTnC {
    &.MuiList-root {
      border-top: 1px dashed #ccc;
      margin-top: 12px;
      padding: 8px 0 0 0px;
      .MuiListItem-root {
        padding: 6px 0px;
        align-items: flex-start;
        .MuiListItemText-root {
          margin: 0px;
        }
        .MuiListItemIcon-root {
          min-width: inherit;
          margin-right: 6px;
          color: #3bb95e;
          margin-top: 3px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        .MuiListItemText-root {
          .MuiTypography-root {
            font-size: 14px;
            color: #666;
            margin: 0px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .MuiCardHeader-root {
      .MuiCardHeader-content {
        .MuiTypography-root {
          font-size: 16px;
        }
      }
    }
    .MuiCardHeader-root {
      .MuiCardHeader-avatar {
        margin-right: 12px;

        .MuiAvatar-root {
          width: 28px;
          height: 28px;
        }
      }
    }

    .MuiCardContent-root {
      h6 {
        margin-top: 12px;
      }
    }
  }
}

.EL-CouponDrawer {
  .EL-DilogueTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 12px 10px 20px;
    font-size: 16px;
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 2;
    background: #fff;
  }
  .EL-CouponCard {
    padding: 16px 4px;
    max-height: 70vh;
    overflow: auto;
    .MuiCardContent-root .MuiTypography-body2 {
      font-weight: 600;
      color: #1a1a1a;
      overflow: inherit;
      text-overflow: inherit;
      -webkit-line-clamp: inherit;
    }
    .MuiCardHeader-root .MuiCardHeader-avatar .MuiAvatar-root {
      width: 42px;
      height: 42px;
    }
    max-height: 70vh;
    overflow: auto;
  }
  @media (max-width: 767px) {
  }
}

.EL-CouponListDrawer {
  .MuiPaper-elevation {
    background: #f7f7fa;
  }
  .EL-CouponListDrawerInner {
    padding: 16px 16px;
    height: 90vh;
    .EL-CouponCard .MuiCardContent-root .MuiTypography-body2 {
      overflow: inherit;
      text-overflow: inherit;
      -webkit-line-clamp: inherit;
    }
  }

  .EL-DilogueTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 12px 10px 20px;
    font-size: 18px;
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 2;
    background: #fff;
  }
  h5 {
    font-weight: 600;
    font-size: 14px;
    color: #666;
    span {
      display: block;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .EL-CouponCard {
    background: #fff;
    padding: 16px 0px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 4px 0px #00000026;
    .MuiCardHeader-root {
      align-items: center;
      .MuiCardHeader-content .MuiTypography-root {
        font-size: 14px;
        min-height: inherit;
      }
    }
    .MuiCardHeader-action {
      margin: 0px;
      -webkit-align-self: inherit;
    }

    .MuiCardContent-root h6 {
      margin-top: 6px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
  }
}

.EL-CouponDialog {
  .EL-DilogueTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 16px 10px 24px;
    font-size: 18px;
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 2;
    background: #fff;
  }
  .MuiPaper-root {
    width: 376px;
    .MuiTypography-body2 {
      font-weight: 600;
      font-size: 14px;
      color: #1a1a1a;
      overflow: inherit;
      text-overflow: inherit;
      -webkit-line-clamp: inherit;
      p,
      ul,
      li {
        margin: 0px;
        padding: 0px;
      }
    }
  }
  .EL-CouponCard {
    .MuiCardHeader-root .MuiCardHeader-content .MuiTypography-root {
      margin-top: -4px;
    }
    .MuiCardHeader-root .MuiCardHeader-avatar .MuiAvatar-root {
      width: 38px;
      height: 38px;
    }
  }
}

.EL-CouponListDialog {
  .El-offerTebMain {
    background: #f7f7fa;
    padding: 24px;
    .EL-CouponTabPanal {
      min-height: 440px;
      max-height: 440px;
      overflow-y: auto;
    }
    .MuiTabs-root {
      min-height: inherit;
      max-height: inherit;
      .MuiButtonBase-root {
        padding: 4px 12px;
        min-height: 32px;
        -webkit-border-top-left-radius: 6px;
        -webkit-border-top-right-radius: 6px;
        -moz-border-radius-topleft: 6px;
        -moz-border-radius-topright: 6px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        &.Mui-selected {
          color: #fff;
          background: #5e6278;
        }
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    .MuiPaper-root {
      .MuiTypography-body2 {
        color: #1a1a1a;
        overflow: inherit;
        text-overflow: inherit;
        -webkit-line-clamp: inherit;
      }
    }
  }
  .MuiDialog-paper {
    width: 800px;
    .EL-CouponCard {
      background: #f7f7fa;
      padding: 16px 4px 8px 4px;
      box-shadow: none;

      .EL-CouponCardInner {
        background: #fff;
        box-shadow: 0px 1px 4px 0px #00000026;
        border-radius: 8px;
        padding: 16px 4px;
        .MuiCardContent-root {
          padding-bottom: 0px;
        }
      }
    }
    .MuiTypography-body2 {
      font-weight: 600;
      font-size: 14px;
      color: #1a1a1a;
      p,
      ul,
      li {
        margin: 0px;
        padding: 0px;
        font-weight: 400;
      }
    }
  }
  .EL-DilogueTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 16px 10px 24px;
    font-size: 18px;
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 2;
    background: #fff;
  }
}

@media (max-width: 767px) {
}
